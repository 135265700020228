import React, { Component } from 'react';
import { LocalizationContext } from 'localize-react';

import OurVisionContent from './our-vision-content';
import { MainMenuSelectedKeys } from '../../constants';
import { Page } from '../page';
import { customTranslate } from '../../utils';
import './vision.css'

export default class OurVisionPage extends Component {

  state = { content: new OurVisionContent() }

  render() {
    MainMenuSelectedKeys.length = 0;
    MainMenuSelectedKeys.push(this.state.content.slug);
    return <Page rootPageLabel={this.state.content.name}>
      <div className="vision block">
        <div className='column'>
          <picture>
            <source srcSet={this.state.content.image} media="(min-width: 1024px)"/>
            <img src={this.state.content.imageMobile} className="full-width" alt="our vision" />
          </picture>

        </div>
        <div className='column'>
          <h1>{customTranslate(this.state.content.title, this.context)}</h1>
          <div dangerouslySetInnerHTML={{__html: customTranslate(this.state.content.text, this.context)}}>
          </div>
        </div>
      </div>
      <div className='full-width'>
        <a href="/what-we-do">
          <button type="button">{customTranslate(this.state.content.whatWeDoButton, this.context)}</button>
        </a>
      </div>
    </Page>
  }
}

OurVisionPage.contextType = LocalizationContext;