import React, { Component } from 'react';
import { Page } from '../page';
import { LocalizationContext } from 'localize-react';
import { customTranslate } from '../../utils';
import { Link } from 'react-router-dom';

export default class Error404Page extends Component {

  render() {
    let homeLabel =customTranslate('menu.home', this.context)
    return <Page rootPageLabel="404">
      <h1 className="page-title">404 - Page not found</h1>
      <h2><Link to="/">{homeLabel}</Link></h2>
      <div className="404 block">
        <div className='column'>
          <picture>
            <source srcSet="/static/png/softtwining-magic-mushrooms.png" media="(min-width: 1024px)"/>
            <img src="/static/png/softtwining-magic-mushrooms-mobile.png" className="full-width" alt="our vision" />
          </picture>

        </div>
        <div className='column'>
          <h2>So two mushrooms walk into an elevator.</h2>
          <p>One turns to the other and says, "There's no mushroom in here is there?"</p>
        </div>
      </div>

    </Page>
  }

}

Error404Page.contextType = LocalizationContext;