import React, { Component } from 'react';
import axios from 'axios';
import { LocalizationContext } from 'localize-react';

import FormErrors from './form-errors';
import GoogleRecaptcha from './google-recaptcha';
import ContactContent from './contact-content';
import { customTranslate } from '../../utils';

export default class ContactForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            content: new ContactContent(),
            name: '',
            email: '',
            msg: '',
            formErrors: { name: '', email: '', msg: '', captcha: '', hasErrors: function () { return !!this.name || this.email || this.msg || this.captcha } },
            nameValid: false,
            emailValid: false,
            msgValid: false,
            formValid: false,
            touched: {
                name: false,
                email: false,
                msg: false,
            },
            isSubmitted: false,
            validFormSubmitted: false,
            captchaResponse: '',
            messageSuccessfullySent: false,
            showSendStatus: false
        }
    }

    handleUserInput = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        this.setState({ [name]: value },
            () => { this.validateField(name, value) });
    }

    handleBlur = (field) => (evt) => {
        this.setState({
            touched: { ...this.state.touched, [field]: true },
        });
    }

    validateField(fieldName, value) {
        let fieldValidationErrors = this.state.formErrors;
        let nameValid = this.state.nameValid;
        let emailValid = this.state.emailValid;
        let msgValid = this.state.msgValid;

        switch (fieldName) {
            case 'name':
                nameValid = value.length >= 3;
                fieldValidationErrors.name = nameValid ? '' : this.state.content.shortError;
                break;
            case 'email':
                emailValid = value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
                fieldValidationErrors.email = emailValid ? '' : this.state.content.invalidError;
                break;
            case 'msg':
                msgValid = value.length >= 5;
                fieldValidationErrors.msg = msgValid ? '' : this.state.content.shortError;
                break;
            default:
                break;
        }
        this.setState({
            formErrors: fieldValidationErrors,
            nameValid: nameValid,
            emailValid: emailValid,
            msgValid: msgValid
        }, this.validateForm);
    }

    validateForm() {
        this.setState({ formValid: this.state.nameValid && this.state.emailValid && this.state.msgValid });
    }

    onSubmit = (e) => {
        e.preventDefault();
        let fieldValidationErrors = this.state.formErrors;
        const { name, email, msg, captchaResponse } = this.state;
        this.setState({ isSubmitted: true });
        if (this.state.formValid) {
            if (captchaResponse) {
                this.setState({ validFormSubmitted: true });
                // console.log(`${name} ${email}  ${msg}`, captchaResponse);
                axios.post('/contact/message', { name, email, msg, captchaResponse })
                    .then(result => {
                        // console.log('send email result: ', result.data.state, result)
                        if(result.data.state === 'ok') {
                            this.setState({
                                name: '',
                                email: '',
                                msg: '',
                                messageSuccessfullySent: true,
                                showSendStatus: true
                            })                            
                        } else {
                            // console.log('Message sending failed due to result.data.state = ', result.data.state)
                            this.setState({
                                messageSuccessfullySent: false,
                                showSendStatus: true
                            })
                        }
                    })
                    .catch(error => {
                        // console.log('Message sending failed: ', error)
                        this.setState({
                            messageSuccessfullySent: false,
                            showSendStatus: true
                        })
                    })
            } else {
                fieldValidationErrors.captcha = customTranslate(this.state.content.captchaError, this.context)
                this.setState({
                    formErrors: fieldValidationErrors
                })
            }
        }

    }

    updateCaptchaResponse = (response) => {
        let fieldValidationErrors = this.state.formErrors;
        if (response) {
            fieldValidationErrors.captcha = ''
            this.setState({
                captchaResponse: response,
                formErrors: fieldValidationErrors,
            })
        }
    }

    render() {

        const shouldMarkError = (field) => {
            const hasError = this.state.formErrors[field];
            const shouldShow = this.state.touched[field];

            return hasError ? shouldShow : false;
        };

        const showErrorPanel = this.state.isSubmitted && this.state.formErrors.hasErrors()

        return (
            <div>
                {showErrorPanel && <FormErrors formErrors={this.state.formErrors} />}

                <form className="contact-form" onSubmit={this.onSubmit}>
                    <div className="block">
                        <div className="column">
                            <div className="form-field">
                                <label htmlFor="name">{customTranslate(this.state.content.contactName, this.context)}</label>
                                <input type="text" required className={`input-field ${shouldMarkError('name') ? "has-error" : ""}`} name="name"
                                    placeholder={customTranslate(this.state.content.contactName, this.context)}
                                    value={this.state.name}
                                    onChange={this.handleUserInput}
                                    onBlur={this.handleBlur('name')} />
                            </div>
                        </div>
                        <div className="column">
                            <div className="form-field">
                                <label htmlFor="email">{customTranslate(this.state.content.contactEmail, this.context)}</label>
                                <input type="text" required className={`input-field ${shouldMarkError('email') ? "has-error" : ""}`} name="email"
                                    placeholder={customTranslate(this.state.content.contactEmail, this.context)}
                                    value={this.state.email}
                                    onChange={this.handleUserInput}
                                    onBlur={this.handleBlur('email')} />
                            </div>
                        </div>
                    </div>
                    <div className="form-field">
                        <label htmlFor="msg">{customTranslate(this.state.content.message, this.context)}</label>
                        <textarea rows="5" cols="25" required className={`input-field ${shouldMarkError('msg') ? "has-error" : ""}`} name="msg"
                            placeholder={customTranslate(this.state.content.message, this.context)}
                            value={this.state.msg}
                            onChange={this.handleUserInput}
                            onBlur={this.handleBlur('msg')} >
                        </textarea>
                    </div>
                    <div className="form-field">
                        <div className="block">
                            <div className="column">
                                <GoogleRecaptcha onVerify={this.updateCaptchaResponse} language={this.context.locale} />
                            </div>
                            <div className="column">
                                <button type="submit" className="btn btn-primary">{customTranslate(this.state.content.submitButton, this.context)}</button>
                            </div>
                        </div>
                    </div>
                </form>
                {this.state.showSendStatus && <div>
                {this.state.messageSuccessfullySent ? <p className="message-sent"> {customTranslate(this.state.content.messageSubmitted, this.context)} </p> :
                    <p className="message-sent error"> {customTranslate(this.state.content.messageFailed, this.context)} </p>}</div>}
            </div>

        )
    }
}

ContactForm.contextType = LocalizationContext;