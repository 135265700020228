import React, { Component } from 'react';
import { LocalizationContext } from 'localize-react';

import HowWeWorkContent from './how-we-work-content';
import { MainMenuSelectedKeys } from '../../constants';
import { Page } from '../page';
import { customTranslate } from '../../utils';

export default class HowWeWorkPage extends Component {

  state = { content: new HowWeWorkContent() }

  render() {
    MainMenuSelectedKeys.length = 0;
    MainMenuSelectedKeys.push(this.state.content.slug);
    return <Page rootPageLabel={this.state.content.name}>
      <h1>{customTranslate(this.state.content.title, this.context)}</h1>
      <h3 style={{textAlign: 'center'}}>{customTranslate(this.state.content.subtitle, this.context)}</h3>
      <div className='full-width'>
        <picture>
          <source media="(max-width:500px)" srcSet={customTranslate(this.state.content.imageMobile, this.context)} />
          <img src={customTranslate(this.state.content.image, this.context)} className="full-width" alt="how we work" />
        </picture>
      </div>
      <div className='full-width'>
        <a href="/contact">
          <button type="button">{customTranslate(this.state.content.contactButton, this.context)}</button>
        </a>
      </div>
    </Page>
  }
}

HowWeWorkPage.contextType = LocalizationContext;