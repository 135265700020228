export default class HowWeWorkContent {
    name = 'privacy-policy-page'
    title = `${this.name}.title`
    subTitle = `${this.name}.subtitle`
    message = `${this.name}.message`
    homeButton = `${this.name}.home-button`
    cookieList = [
        {
            name: 'DIV',
            description: `${this.name}.div-cookie-description`
        },
        {
            name: '1P_JAR',
            description: `${this.name}.jar-cookie-description`
        },
        {
            name: 'NID',
            description: `${this.name}.nid-cookie-description`
        },
        {
            name: 'rcl_preferences_consent',
            description: `${this.name}.rcl-preferences-cookie-description`
        },
        {
            name: 'rcl_statistics_consent',
            description: `${this.name}.rcl-statistics-cookie-description`
        },
        {
            name: 'rcl_consent_given',
            description: `${this.name}.rcl-consent-cookie-description`
        }
    ]
}