import React, { Component } from 'react';
import { LocalizationContext } from 'localize-react';
import Obfuscate from 'react-obfuscate';

import ContactContent from './contact-content';
import { MainMenuSelectedKeys } from '../../constants';
import { Page } from '../page';
import ContactForm from './contact-form';
import { customTranslate } from '../../utils';
import './contact.css';

export default class ContactPage extends Component {

  state = { content: new ContactContent() }

  render() {
    MainMenuSelectedKeys.length = 0;
    MainMenuSelectedKeys.push(this.state.content.slug);
    return <Page rootPageLabel={this.state.content.name}>
      <h1>{customTranslate(this.state.content.title, this.context)}</h1>
      <h2>{customTranslate(this.state.content.contactUsText, this.context)}</h2>
      <div className="block">

        <div className="column-contact">
          <p className="bold">{customTranslate(this.state.content.detailsLabel, this.context)}</p>
          <label>E-mail - </label>
          <Obfuscate email={this.state.content.ourEmail}/>
          <p className="bold">{customTranslate(this.state.content.headquarterAddressLabel, this.context)}</p>
          <p>{this.state.content.ourAddress}</p>
          <p className="bold">{customTranslate(this.state.content.workstationAddressLabel, this.context)}</p>
          <p>{this.state.content.ourWorkAddress}</p>
        </div>
        <div className="column">
          <ContactForm />
        </div>
      </div>
    </Page >
  }
}

ContactPage.contextType = LocalizationContext;