export default class ContactContent {
  name = 'contact'
  slug = 'contact'
  title = `${this.name}.title`
  description = 'This is the contact page'
  shortError = `${this.name}.short-error`
  invalidError = `${this.name}.invalid-error`
  captchaError = `${this.name}.captcha`
  contactName = `${this.name}.contact-name`
  contactEmail = `${this.name}.contact-email`
  message = `${this.name}.contact-message`
  submitButton = `${this.name}.form-button`
  messageSubmitted = `${this.name}.message-submitted`
  messageFailed = `${this.name}.message-failed`
  contactUsText = `${this.name}.contact-us`
  detailsLabel = `${this.name}.details-label`
  headquarterAddressLabel = `${this.name}.headquarter-label`
  workstationAddressLabel = `${this.name}.workstation-label`
  ourEmail = `info@softtwining.ro`
  ourAddress = `Calea Torontalului 15A, Ap.9, 300627, Timișoara, România`
  ourWorkAddress = `Ady Endre nr. 15, Ap.8, 300175, Timișoara, Timiș`
}