export const MainMenuSelectedKeys = ['home'];
export const LANGUAGE_RO = 'RO';
export const LANGUAGE_EN = 'EN';
export const IS_LOCALIZE_CACHE_DISABLED = false;
export const CAPTCHA_SITE_KEY = '6LeVO68ZAAAAAAorwxQdTRXMvVjMbW4zduot8qoR';
export const TRANSLATIONS = {
  en: {
    menu: {
      home: 'Home',
      'our-vision': 'Our Vision',
      'what-we-do': 'What do we do?',
      'how-we-work': 'How do we work?',
      vr360: 'VR 360',
      contact: 'Contact',
    },
    footer: {
      'footer-row-1': 'Website created by the Startup Nation 2017 program',
      'footer-row-2': 'All rights reserved © 2020 - Soft Twining Technologies SRL-D',
    },
    contact: {
      title: 'Contact Us',
      'form-button': 'Send',
      'contact-message': 'Message',
      'contact-email': 'E-mail address',
      'contact-name': 'Name',
      'short-error': ' is too short',
      'invalid-error': ' is invalid',
      name: 'Name',
      email: 'E-mail address',
      msg: 'Message',
      'message-submitted': 'Message sent.',
      'message-failed': 'Message could not be sent.',
      captcha: 'Please verify you are human!',
      'contact-us': 'If you want to collaborate or if you have a question do not hesitate and contact us ...',
      'details-label':'Contact details Soft Twining Technologies SRL-D',
      'headquarter-label':'Headquarters address',
      'workstation-label':'Workstation address',
    },
    'what-we-do-page': {
      title: 'What are we actually doing?',
      subtitle: 'Soft Twining Technologies is like Disneyland for programmers',
      hybris: 'Consulting services as a Hybris developer that includes the development of the existing Hybris B2C product, design of solutions based on Hybris accelerator, which adds new functionalities and interaction with technical and non-technical staff for the development of solutions.',
      springboot: 'Software development services based on frameworks such as SpringBoot, Spring Cloud, Thymeleaf using the technique of "micro-services" and "web-services" with Oracle or MySQL databases behind them and frontend using multiple fremeworks like Angular, React or VueJs.',
      scrum: '"Agile" development process implementation services such as SCRUM, KANBAN or others.',
      spring: 'Development and optimization services for stock exchange software solutions with Spring, Grails, Hibernate, Maven, NoSQL technologies.',
      ecommerce: 'Ecommerce platform solutions with multiple vendors of the "Marketplace" type with bank credit integration as a payment method using Hybris accelerator v5 as well as customized extensions, order management, process engines, custom cockpits, CMS.',
      cloud: 'Software infrastructure services using virtual machines, cloud services (AWS, Azure or Google Cloud) that involve installation and maintenance of enterprise servers such as Apache, Tomcat, Glassfish, Jboss, Weblogic and Oracle databases, MS SQL, My SQL, Mong DB, REDIS, DynamoDB.',
      vr: 'Development of virtual reality software applications and 360 spherical images.',
      business: 'Business modeling services with BPMN or S-BPM with Metasonic Suite.',
      java: 'Technical training services in Java, Java EE, Spring, ORM (JPA, Hibernate, iBatis), project build tools and non-technical agile development processes such as Kanban, SCRUM.',
      pm: 'Software project coordination and development services.',
      ai: 'Solutions for integrating artificial intelligence algorithms (K-means, SVM, NN, RNN) to solve business problems such as image recognition, anomaly detection, product recommendations.',
      'how-we-work-button': 'How do we work?',
    },
    'our-vision-page': {
      title: 'Our Vision',
      text: 'We are SOFT TWINING TECHNOLOGIES and we are your future technological romance.<br/>A team of misfit geniuses conquering the world through creative enterprise solutions with various programming languages, e-commerce systems, research and education programs, using state-of-the-art technologies and real emotions engaging machine learning and virtual reality in business programming.<br/>To worry about what people think of us is a waste of time. As long as we stay true to ourselves, we are living our dream. We see programming as the only way forward, and we will never sell out or abandon our values. Standing up for our opinions and beliefs is the only way we know. We walk with our backs straight on the path to pursuing our truth. <br/>With one foot in front of the other, we seek to challenge conservative frameworks. We are doctors of disturbance, wizards of disruption and spokesmen of disobedience when finding new solutions. We assume the responsibility to stay engaged, give a damn, and strive for empathy.<br/> There is a short word for this assumption of responsibility: PASSION.',
      'what-we-do-button': 'What do we do?',
    },
    'how-we-work-page': {
      title: 'How do we work?',
      subtitle: 'The customer perception is Soft Twining Technologies virtual reality',
      image: '/static/png/soft-twining-how-do-we-work-en.png',
      'image-mobile': '/static/png/soft-twining-how-do-we-work-mobile-en.png',
      'contact-button': 'Contact us'
    },
    'home-page': {
      title: 'Soft Twining Technologies',
      description: 'comes from the technique of combining state-of-the-art technologies, in order to offer software solutions dedicated to corporations. <br/><br/> "What if" is the seed of Soft Twining Technologies resolution.',
      'button-text': 'Find out more',
    },
    'cookie-notification': {
      message: 'We use cookies to personalize content and ads, to provide social networking features, and to analyze traffic. We also provide social media, advertising and analytics partners with information on how you use our site. ',
      necessary: 'Strictly necessary cookies',
      'third-party': 'Third party cookies',
      'privacy-policy': 'Privacy policy'
    },
    'privacy-policy-page': {
      title: 'Privacy Overview',
      subtitle: 'Strictly necessary cookies',
      message: 'This website uses cookies so that we can provide you with the best possible user experience. Cookie information is stored in your browser and performs functions such as your recognition when you return to our site and helping our team understand which sections of the site you find most interesting and useful.',
      'div-cookie-description': 'Cookie used by Google ReCaptcha',
      'jar-cookie-description': 'Cookie used by Google ReCaptcha',
      'nid-cookie-description': 'Cookie used by Google ReCaptcha',
      'rcl-preferences-cookie-description': 'GDPR Consent Cookie',
      'rcl-statistics-cookie-description': 'GDPR Consent Cookie',
      'rcl-consent-cookie-description': 'GDPR Consent Cookie',
      'home-button': 'Home'
    }
  },
  ro: {
    menu: {
      home: 'Acasă',
      'our-vision': 'Viziunea noastră',
      'what-we-do': 'Ce facem?',
      'how-we-work': 'Cum lucrăm?',
      vr360: 'VR 360',
      contact: 'Contact',
    },
    footer: {
      'footer-row-1': 'Site creat prin programul Startup Nation 2017',
      'footer-row-2': 'Toate drepturile rezervate © 2020 - Soft Twining Technologies SRL-D',
    },
    contact: {
      title: 'Contactează-ne',
      'form-button': 'Trimite',
      'contact-message': 'Mesaj',
      'contact-email': 'Adresa de e-mail',
      'contact-name': 'Nume',
      'short-error': ' e prea scurt',
      'invalid-error': ' nu e validă',
      name: 'Numele',
      email: 'Adresa de e-mail',
      msg: 'Mesajul',
      'message-submitted': 'Mesaj trimis.',
      'message-failed': 'Mesajul nu a putut fi trimis.',
      captcha: 'Vă rugăm verificați că nu sunteți robot!',
      'contact-us': 'Dacă vreți să colaborăm sau dacă aveți o nelămurire nu ezitați si contactați-ne...',
      'details-label':'Date contact Soft Twining Technologies SRL-D',
      'headquarter-label':'Adresă sediu social',
      'workstation-label':'Adresă punct de lucru'
    },
    'what-we-do-page': {
      title: 'Ce facem noi de fapt?',
      subtitle: 'Soft Twining Technologies este precum Disneyland pentru dezvoltatori',
      hybris: 'Servicii de consultanță ca și dezvoltator Hybris ce include dezvoltarea produsului existent Hybris B2C, design de soluții bazate pe Hybris accelerator, care adaugă funcționalități noi și interacțiune cu personalul tehnic si non-tehnic pentru dezvoltarea soluțiilor.',
      springboot: 'Servicii de dezvoltare software bazate pe framework-uri precum SpringBoot, Spring Cloud, Thymeleaf folosind tehnica de „micro-services” și „web-services” având în spate baze de date Oracle sau MySQL si frontend cu multiple framework-uri precum VueJs, Angular sau React.',
      scrum: 'Servicii de implementare a proceselor de dezvoltare „Agile” precum SCRUM, KANBAN sau altele.',
      spring: 'Servicii de dezvoltare si optimizare a soluțiilor software pentru bursa de valori cu tehnologii Spring, Grails, Hibernate, Maven, NoSQL.',
      ecommerce: 'Soluții de platforme e-commerce cu furnizori multipli de tip „Marketplace” cu integrare de credite bancare ca și modalitate de plată folosind Hybris accelerator v5 precum și extensii personalizate, management comenzi, motoare de procesare, cockpit-uri personalizate, CMS.',
      cloud: 'Servicii de infrastructură software folosind mașini virtuale, servicii cloud (AWS, Azure sau Google Cloud) care presupun instalare și mentenanță, servere enterprise precum Apache, Tomcat, Glassfish, Jboss, Weblogic si baze de date Oracle, MS SQL, My SQL, Mongo DB, REDIS, DynamoDB.',
      vr: 'Dezvoltare aplicații software de realitate virtuală și imagini sferice 360.',
      business: 'Servicii de modelare business cu BPMN sau S-BPM cu Metasonic Suite.',
      java: 'Servicii de training tehnic in Java, Java EE, Spring, ORM (JPA, Hibernate, iBatis), project build tools și non-tehnic a proceselor de dezvoltare agile precum Kanban, SCRUM.',
      pm: 'Servicii de coordonare și dezvoltare proiecte software (project leadership și management).',
      ai: 'Soluții de integrare algoritmi de inteligență artificială (K-means, SVM, NN, RNN) pentru a rezolva probleme de business precum recunoașterea imaginilor, detectarea anomaliilor, recomandări produse.',
      'how-we-work-button': 'Cum lucrăm?',
    },
    'our-vision-page': {
      title: 'Viziunea noastră',
      text: 'Noi suntem SOFT TWINING TECHNOLOGIES, viitorul romantism technologic. <br/> Mai pe scurt o echipă de genii inadaptate care cuceresc lumea tech cu soluțiile particularizate creative bazate o varietate de limbaje de programare, systeme e-commerce, cercetare și dezvoltare de programe educationale, folosind tehnologii state-of-the-art, totodată apelând la emoții reale prin intermediul machine learning și al realității virtuale. <br/> A ne face griji despre ce cred oamenii despre noi ni se pare o pierdere de vreme. Atâta timp cât rămânem sinceri cu noi înșine, ne trăim în continuare visul. Noi vedem programarea ca pe o constantă spre înainte și nu ne vom abandona niciodată valorile. <br/> A lupta pentru principii reprezintă singura cale pe care o cunoaștem Înaintăm așadar cu spatele drept și fruntea sus pe calea spre adevăr. Mereu cu un pas în față, căutăm să desfințăm și să restructurăm cadre conservatoare. Noi suntem vrajitori perturbatori sau agenți "provocatori", purtătorii de cuvânt ai nesupunerii când vine vorba de soluții noi. În egală măsură ne asumăm responsabilitatea de a rămâne angajați conștiincioși până la final, luptând pentru empatie. <br> Există un singur cuvânt pentru această asumare a responsabilității : PASIUNE.',
      'what-we-do-button': 'Ce facem de fapt?',
    },
    'how-we-work-page': {
      title: 'Cum lucrăm?',
      subtitle: 'Perceptia clientului este realitate virtuala pentru Soft Twining Technologies',
      image: '/static/png/soft-twining-cum-lucram-ro.png',
      'image-mobile': '/static/png/soft-twining-cum-lucram-mobile-ro.png',
      'contact-button': 'Contactează-ne'
    },
    'home-page': {
      title: 'Soft Twining Technologies',
      description: 'provine de la tehnica de îmbinare a tehnologiilor ultramoderne, pentru a oferi soluții software personalizate. <br/><br/> "What if" este sămânța rezoluției Soft Twining Technologies.',
      'button-text': 'Află mai mult',
    },
    'cookie-notification': {
      message: 'Folosim cookie-uri pentru a personaliza conținutul și anunțurile, pentru a oferi funcții de rețele sociale și pentru a analiza traficul. De asemenea, le oferim partenerilor de rețele sociale, de publicitate și de analize informații cu privire la modul în care folosiți site-ul nostru. Pentru mai multe detalii puteți accesa politica de confidențialitate.',
      necessary: 'Cookie-uri strict necesare',
      'third-party': 'Cookie-uri părți terțe',
      'privacy-policy': 'Politica de confidențialitate'
    },
    'privacy-policy-page': {
      title: 'Politica de confidențialitate',
      subtitle: 'Cookie-uri strict necesare',
      message: 'Acest site web folosește cookie-uri, astfel încât să vă putem oferi cea mai bună experiență de utilizator posibilă. Informațiile despre cookie-uri sunt stocate în browserul dvs. și îndeplinesc funcții precum recunoașterea dvs. când vă întoarceți pe site-ul nostru și ajutarea echipei noastre să înțeleagă ce secțiuni ale site-ului vi se par cele mai interesante și utile.',
      'div-cookie-description': 'Cookie folosit de Google ReCaptcha',
      'jar-cookie-description': 'Cookie folosit de Google ReCaptcha',
      'nid-cookie-description': 'Cookie folosit de Google ReCaptcha',
      'rcl-preferences-cookie-description': 'Consimțământ GDPR',
      'rcl-statistics-cookie-description': 'Consimțământ GDPR',
      'rcl-consent-cookie-description': 'Consimțământ GDPR',
      'home-button': 'Acasă'
    }
  }
};

