import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { LocalizationContext } from 'localize-react';

import HeaderContent from './header-content';
import Logo from './logo';
import { customTranslate } from '../../utils';
import './header.css';

export default class Header extends Component {

    constructor(props) {
        super(props)
        this.state = {
            content: new HeaderContent(),
            checked: false
        }
        this.handleMenuClick.bind(this)
        this.handleLinkClick.bind(this)
    }

    handleLinkClick = () => {
        this.setState({ checked: false })
    }

    handleMenuClick = () => {
        this.setState({ checked: !this.state.checked })
    }

    render() {
        return (
            <header className="header">
                <Logo />
                <button className={this.state.checked ? "menu-btn-checked" : "menu-btn"} id="menu-btn" />
                <label className="menu-icon" htmlFor={this.state.checked ? "menu-btn-checked" : "menu-btn"} onClick={this.handleMenuClick}>
                  <span className="navicon">&nbsp;</span>
                </label>
                <ul className="menu" onClick={this.handleLinkClick}>
                    {this.state.content.navLinks.map((menuItem, index) => <li key={index}><Link key={index} to={menuItem.link} className={index === 0 ? "home-link" : ""}> {customTranslate(menuItem.title, this.context)} </Link></li>)}
                </ul>

            </header>
        )
    }
}

Header.contextType = LocalizationContext;