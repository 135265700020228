export default class HeaderContent {
    name= `menu`
    navLinks = [
        {
            link: `/`,
            title: `${this.name}.home`,
            exact: true,
        },
        {
            link: `/our-vision`,
            title: `${this.name}.our-vision`,
        },
        {
            link: `/what-we-do`,
            title: `${this.name}.what-we-do`,
        },
        {
            link: `/how-we-work`,
            title: `${this.name}.how-we-work`,
        },
        // {
        //     link: `/360`,
        //     title: `${this.name}.vr360`,
        // },
        {
            link: `/contact`,
            title: `${this.name}.contact`,
        },
    ];
}