import React, { Component } from 'react';
import { LocalizationContext } from 'localize-react';

import { Page } from '../page';
import { MainMenuSelectedKeys } from '../../constants';
import { customTranslate } from '../../utils';
import HomeContent from './home-content';
import './home-page.css';

export default class HomePage extends Component {
  state = { content: new HomeContent() };

  render() {
    MainMenuSelectedKeys.length = 0;
    MainMenuSelectedKeys.push(this.state.content.slug);
    let title = customTranslate(this.state.content.title, this.context);
    let btnName = customTranslate(this.state.content.buttonText, this.context);
    return (
      <Page rootPageLabel={this.state.content.name}>
        <div className="home-container">
          <div className="home-text">
            <h1>{title}</h1>
            <div dangerouslySetInnerHTML={{__html: customTranslate(this.state.content.description, this.context)}}>
            </div>

            <a href="/our-vision">
              <button type="button">{btnName}</button>
            </a>
          </div>
        </div>
      </Page>
    );
  }
}

HomePage.contextType = LocalizationContext;
