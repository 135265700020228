import HomeContent from '../home/home-content';

export default class Vr360Content{
  name = '360 Name' 
  slug = '360'
  title = '360 VR'
  iframes = [
    {title : 'Soft Twining 360 Logo', url: 'https://www.osphaera.ro/embedded/GmeYFjttpkIjcChIvYP8'},
    {title : 'Hotel Nevis - Spa', url: 'https://www.osphaera.ro/embedded/vOJkrAng0KISsCoVYUKS'}]
  homeContent = new HomeContent()
}
