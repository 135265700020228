import React, { Component } from 'react';

export default class Vr360Iframes extends Component {


  render() {
    const { content } = this.props;
    return (
      <div>{content.iframes.map((f, i) => <div key={i}><h2>{f.title}</h2>
        <iframe src={f.url} title={f.title} allow={"gyroscope,accelerometer,fullscreen"} width="100%" height="480px" scrolling="no"
                allowFullScreen={true} frameBorder={0}>&nbsp;</iframe>
      </div>)}</div>
    );
  }

}
