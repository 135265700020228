export const customTranslate = (keyToTranslate, context) => {
  let lang = context.locale;
  let dictionary = context.translations;
  let translationValue = dictionary[lang.toLowerCase()];
  if (translationValue) {
    let keyTokens = keyToTranslate.split('.');
    for (let keyToken of keyTokens) {
      translationValue = translationValue[keyToken];
      // console.log('translate ', keyToken, translationValue)
    }
  }

  return translationValue;
}