import React, { Component } from 'react';
import { LocalizationContext } from 'localize-react';

import PrivacyPolicyContent from './privacy-policy-content';
import { Page } from '../page';
import { customTranslate } from '../../utils';
import './privacy-policy.css';

export default class PrivacyPolicyPage extends Component {

    state = { content: new PrivacyPolicyContent() }

    render() {
        return <Page rootPageLabel={this.state.content.title}>
            <h1>{customTranslate(this.state.content.title, this.context)}</h1>
            <div className='full-width'>
                <p className='paragraph'>{customTranslate(this.state.content.message, this.context)}</p>
                <h2>{customTranslate(this.state.content.subTitle, this.context)}</h2>
                <div>
                    {this.state.content.cookieList.map((cookie, i) => <p key={i} className='paragraph'> {`${cookie.name} - ${customTranslate(cookie.description, this.context)}`} </p>)}
                </div>
                <a href="/">
                    <button type="button" className='home-button'>{customTranslate(this.state.content.homeButton, this.context)}</button>
                </a>
            </div>
        </Page>
    }
}

PrivacyPolicyPage.contextType = LocalizationContext;