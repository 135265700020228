import React, { Component } from 'react';

import { CAPTCHA_SITE_KEY } from './../../constants';

export default class GoogleRecaptcha extends Component {

  componentDidMount() {
    window.APP_GLOBALS.verifyCallback = (response) => {
      if (response) {
        return this.props.onVerify(response);
      }
    };

    window.APP_GLOBALS.onloadRecaptchaCallback = () => {
      if (window.grecaptcha) {
        window.grecaptcha.render('g-recaptcha', {
          'sitekey': CAPTCHA_SITE_KEY,
          'callback': window.APP_GLOBALS.verifyCallback,
          'theme': 'dark',
          'hl': this.props.language ? this.props.language.toLowerCase() : 'en'
        });
      }
    };

    const callbackScript = document.createElement('script');
    callbackScript.id = "recaptcha-callback-script"
    callbackScript.text = "var onloadRecaptchaCallback = function() {window.APP_GLOBALS.onloadRecaptchaCallback()}"
    document.head.appendChild(callbackScript);

    const apiScript = document.createElement('script');
    apiScript.id = "recaptcha-api-script"
    apiScript.src = 'https://www.google.com/recaptcha/api.js?onload=onloadRecaptchaCallback&render=explicit';
    apiScript.async = true;
    apiScript.defer = true;
    document.head.appendChild(apiScript);
  }

  componentWillUnmount() {
    let apiScript = document.getElementById("recaptcha-api-script");
    if (apiScript){
      document.head.removeChild(apiScript)
    }
    let callbackScript = document.getElementById("recaptcha-callback-script");
    if (callbackScript){
      document.head.removeChild(callbackScript)
    }
    let scripts = Array.from(document.head.getElementsByTagName("script"));
    let recaptchaScript = scripts.find(e => e.src.indexOf('recaptcha') > -1);
    if (recaptchaScript){
      document.head.removeChild(recaptchaScript)
    }
  }

  render() {
    return (<div id="g-recaptcha" className="g-recaptcha">&nbsp;</div>);
  }
}
