import React, { Component } from 'react';
import { LocalizationContext } from 'localize-react';

import { customTranslate } from '../../utils';

export default class FormErrors extends Component {

  render() {
    return (
      <div className="error-panel">
        {Object.keys(this.props.formErrors).map((fieldName, i) => {
          if (this.props.formErrors[fieldName].length > 0) {
            return (
              <p key={i}>{customTranslate(`contact.${fieldName}`, this.context)} {customTranslate(this.props.formErrors[fieldName], this.context)}</p>
            )
          } else {
            return '';
          }
        })}
      </div>
    )
  }
}

FormErrors.contextType = LocalizationContext;