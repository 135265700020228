import React, { Component } from 'react';
import { LocalizationContext } from 'localize-react';

import WhatWeDoContent from './what-we-do-content';
import { MainMenuSelectedKeys } from '../../constants';
import { Page } from '../page';
import { customTranslate } from '../../utils';
import './what-we-do.css';

export default class WhatWeDoPage extends Component {

  state = { content: new WhatWeDoContent() }

  renderBlockContent = (text, image) =>
    <div className="block">
      <div className='column'>
        <p className="column-text">{customTranslate(text, this.context)}&lrm;</p>
      </div>
      <div className='column'>
        <img className="column-image" src={image} alt={text.substring(0, 9)} />
      </div>
    </div>

  renderBlock = (data, index) =>
    index % 2 ?
      <div key={index}>
        <div className="left">
          {this.renderBlockContent(data.text, data.image)}
        </div>
        {index < this.state.content.serviceList.length - 1 &&
          <div className="row-separator">
            <img src="/static/png/soft-twining-ce-facem-icon-de-legatura-galben-ltr.png" alt="separator" />
          </div>
        }
      </div> :
      <div key={index}>
        <div className="right">
          {this.renderBlockContent(data.text, data.image)}
        </div>
        {index < this.state.content.serviceList.length - 1 &&
          <div className="row-separator">
            <img src="/static/png/soft-twining-ce-facem-icon-de-legatura-galben-rtl.png" alt="separator" />
          </div>
        }
      </div>


  render() {
    MainMenuSelectedKeys.length = 0;
    MainMenuSelectedKeys.push(this.state.content.slug);
    return <Page rootPageLabel={this.state.content.name}>
      <h1 className="page-title">{customTranslate(this.state.content.title, this.context)}</h1>
      <h3 className="page-subtitle">{customTranslate(this.state.content.subtitle, this.context)}</h3>
      <section>
        {this.state.content.serviceList.map((s, i) => this.renderBlock(s, i))}
        <div className='full-width'>
          <a href="/how-we-work">
            <button type="button">{customTranslate(this.state.content.howWeWorkButton, this.context)}</button>
          </a>
        </div>
      </section>
    </Page>
  }
}

WhatWeDoPage.contextType = LocalizationContext;