export default class WhatWeDoContent {
  name = `what-we-do-page`
  slug = `what-we-do`
  title = `${this.name}.title`
  subtitle = `${this.name}.subtitle`
  howWeWorkButton = `${this.name}.how-we-work-button`
  serviceList = [
    {
      text: `${this.name}.hybris`,
      image: `/static/png/soft-twining-ce-facem-icon-01.png`
    },
    {
      text: `${this.name}.springboot`,
      image: `/static/png/soft-twining-ce-facem-icon-02.png`
    },
    {
      text: `${this.name}.scrum`,
      image: `/static/png/soft-twining-ce-facem-icon-03.png`
    },
    {
      text: `${this.name}.spring`,
      image: `/static/png/soft-twining-ce-facem-icon-04.png`
    },
    {
      text: `${this.name}.ecommerce`,
      image: `/static/png/soft-twining-ce-facem-icon-05.png`
    },
    {
      text: `${this.name}.cloud`,
      image: `/static/png/soft-twining-ce-facem-icon-06.png`
    },
    {
      text: `${this.name}.vr`,
      image: `/static/png/soft-twining-ce-facem-icon-07.png`
    },
    {
      text: `${this.name}.business`,
      image: `/static/png/soft-twining-ce-facem-icon-08.png`
    },
    {
      text: `${this.name}.java`,
      image: `/static/png/soft-twining-ce-facem-icon-09.png`
    },
    {
      text: `${this.name}.pm`,
      image: `/static/png/soft-twining-ce-facem-icon-10.png`
    },
    {
      text: `${this.name}.ai`,
      image: `/static/png/soft-twining-ce-facem-icon-11-1.png`
    },
  ]
}