import React, { Component } from 'react';
import { LocalizationContext } from 'localize-react';
import { CookieBanner } from '@palmabit/react-cookie-law';

import { customTranslate } from '../../utils';
import CookieNotificationContent from './cookie-notification-content';
import './cookie-notification.css';

export default class CookieNotification extends Component {

    constructor(props) {
        super(props);
        this.state = {
            content: new CookieNotificationContent(),
            showPopup: false
        };
    }

    togglePopup() {
        this.setState({
            showPopup: !this.state.showPopup
        });
    }

    render() {
        return (
            <div>
                <CookieBanner
                    message={customTranslate(this.state.content.message, this.context)}
                    necessaryOptionText={customTranslate(this.state.content.necessary, this.context)}
                    preferencesOptionText={customTranslate(this.state.content.thirdParty, this.context)}
                    showStatisticsOption={false}
                    showMarketingOption={false}
                    showPreferencesOption={false}
                    privacyPolicyLinkText={customTranslate(this.state.content.privacyPolicy, this.context)}
                    policyLink="/privacy-policy"
                    wholeDomain={true}
                    onAccept={() => { }}
                    onAcceptPreferences={() => { }}
                    onAcceptStatistics={() => { }}
                    onAcceptMarketing={() => { }}
                />
            </div>
        )
    }
}

CookieNotification.contextType = LocalizationContext;