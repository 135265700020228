import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import HomePage from './components/home/home-page';
import Vr360Page from './components/360/vr360-page';
import Header from './components/header/header';
import Footer from './components/footer/footer';
import ContactPage from './components/contact/contact-page';
import WhatWeDoPage from './components/what-we-do/what-we-do-page';
import OurVisionPage from './components/vision/our-vision-page';
import HowWeWorkPage from './components/how-we-work/how-we-work-page';
import Language from './components/language';
import { LocalizationProvider } from 'localize-react';
import { LANGUAGE_EN, IS_LOCALIZE_CACHE_DISABLED, TRANSLATIONS } from './constants';
import CookieNotification from './components/gdpr/cookie-notification';
import PrivacyPolicyPage from './components/gdpr/privacy-policy-page';
import Error404Page from './components/errors/Error404Page';

export default class App extends Component {

  constructor(props) {
    super(props)

    this.state = {
      title: "App title",
      language: localStorage.getItem('language') ? localStorage.getItem('language') : LANGUAGE_EN,
    };

    this.changeLanguage = this.changeLanguage.bind(this)
  }

  changeLanguage(language) {
    localStorage.setItem('language', language)
    this.setState({
      language: language
    })
  }



  render() {
    return (
      <LocalizationProvider
        disableCache={IS_LOCALIZE_CACHE_DISABLED}
        locale={this.state.language}
        translations={TRANSLATIONS}
      >
        <Router>

          <section id="content-wrap">
            <CookieNotification />
            <section>
              <Header />
            </section>
            <section className="content">
              <Switch>
                <Route exact path="/" component={HomePage}/>
                <Route exact path="/360" component={Vr360Page}/>
                <Route exact path="/contact" component={ContactPage}/>
                <Route exact path="/how-we-work" component={HowWeWorkPage}/>
                <Route exact path="/our-vision" component={OurVisionPage}/>
                <Route exact path="/what-we-do" component={WhatWeDoPage}/>
                <Route exact path="/privacy-policy" component={PrivacyPolicyPage}/>
                <Route component={Error404Page}/>
              </Switch>
              <Language changeLanguage={this.changeLanguage} currentLanguage={this.state.language} />
            </section>
            <section>
              <Footer />
            </section>
          </section>
        </Router>
      </LocalizationProvider>
    );
  }
}
